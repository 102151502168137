import React, { useState, useEffect } from 'react';
// import { AiFillEye } from 'react-icons/ai';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Events.scss';

const Events = () => {
  const [events, setEvents] = useState([]);
  const [filterEvents, setFilterEvents] = useState([]);
  const [activeFilter, setActiveFilter] = useState('Charity');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  useEffect(() => {
    const query = '*[_type == "events"]';

    client.fetch(query).then((data) => {
      setEvents(data);
      setFilterEvents(data);
    });
  }, []);

  const handleEventsFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      setFilterEvents(events.filter((event) => event.tags.includes(item)));
    }, 500);
  };

  return (
    <>
      <h2 className="head-text">The Club Events</h2>
      <span>** All the profits will go to charity **</span>

      <div className="app__events-filter">
        {['Charity', 'Donation', 'Training', 'Parties'].map((item, index) => (
          <div
            key={index}
            onClick={() => handleEventsFilter(item)}
            className={`app__events-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 1, delayChildren: 0.5 }}
        className="app__events-portfolio"
      >
        {filterEvents.map((event, index) => (
          <a href={event.eventLink} target="_blank" rel="noreferrer">
            <div className="app__events-item app__flex" key={index}>
              <div
                className="app__events-img app__flex"
              >
                <img src={urlFor(event.imgUrl)} alt={event.title} />
              </div>
              <h2>{event.title}</h2>
              <h5>{event.desc}</h5>
            </div>
          </a>
        ))}
      </motion.div>

    </>
  );
};

export default AppWrap(
  MotionWrap(Events, 'app__events'),
  'club events',
  'app__primarybg',
);
