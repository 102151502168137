import React from 'react';
import { NavigationDots, SocialMedia } from '../components';

const AppWrap = (Component, idName, classNames) => function HOC() {
  return (
    <div id={idName} className={`app__container ${classNames}`}>
      <SocialMedia />
      <div className="app__wrapper app__flex">
        <Component />

        <div className="copyright">
          <p className="p-text" style={{ color: 'black' }}>@2024 <b><a href="https://www.instagram.com/mbarki_amidou/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: 'purple' }}>Amidou</a></b></p>
          <p className="p-text" style={{ color: 'black' }}>All rights reserved</p>
        </div>
      </div>
      <NavigationDots active={idName} />
    </div>
  );
};

export default AppWrap;
