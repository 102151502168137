import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Members.scss';

const Members = () => {
  const [members, setMembers] = useState([]);
  const [filterMembers, setFilterMembers] = useState([]);
  const [activeFilter, setActiveFilter] = useState('All Members');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  useEffect(() => {
    const membersQuery = '*[_type == "members"]';

    client.fetch(membersQuery).then((data) => {
      setMembers(data);
      setFilterMembers(data);
    });
  }, []);

  const handleMembersFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === 'All Members') {
        setFilterMembers(members);
      } else {
        setFilterMembers(members.filter((member) => member.tag === item));
      }
    }, 500);
  };

  return (
    <>
      <h2 className="head-text">Our Club Members</h2>

      <div className="app__member-filter">
        {['President', 'Vice President', 'Events', 'Human Resources', 'Média', 'Secretaries', 'Sponsoring', 'Treasurers', 'All Members'].map((item, index) => (
          <div
            key={index}
            onClick={() => handleMembersFilter(item)}
            className={`app__member-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 1, delayChildren: 0.5 }}
        className="app__member-portfolio"
      >
        {filterMembers.map((member, index) => (
          <motion.div
            animate={animateCard}
            whileInView={{ opacity: [0, 1] }}
            transition={{ duration: 1 }}
            className="app__members-item app__flex"
            key={index}
          >
            <div
              className="app__flex"
              style={{ backgroundColor: member.bgColor }}
            >
              <img src={urlFor(member.icon)} alt={member.name} />
            </div>
            <p className="p-text">{member.name}</p>
            <p className="p-text">{member.tag}</p>
          </motion.div>
        ))}
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Members, 'app__members'),
  'our members',
  'app__whitebg',
);
