import React from 'react';

import { Footer, Goals, Header, Members, Testimonial, Events } from './container';
import { Navbar } from './components';
import './App.scss';

const App = () => (
  <div className="app">
    <Navbar />
    <Header />
    <Goals />
    <Events />
    <Members />
    <Testimonial />
    <Footer />
  </div>
);

export default App;
