import React from 'react';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';
import './Header.scss';

const Header = () => (
  <div className="app__header app__flex">
    <motion.div
      whileInView={{ y: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 1 }}
      className="app__header-info"
    >
      <h3 className="head-text">welcome to</h3>
    </motion.div>
    <motion.div
      whileInView={{ y: [-200, 0], opacity: [0, 1] }}
      transition={{ duration: 1 }}
      className="app__header-info"
    >
      <h1 className="head-text">Club CRT ISSISO</h1>
    </motion.div>
  </div>
);

export default AppWrap(Header, 'home');
