import React from 'react';
import { FaInstagram } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <a href="https://www.instagram.com/club_crtissiso/" target="_blank" rel="noreferrer">
      <div className="InstaIcon">
        <FaInstagram />
      </div>
    </a>
  </div>
);

export default SocialMedia;
